import { Injectable } from '@angular/core';
import { ActivatedRoute, Route, RouterState } from '@angular/router';
import { Observable, combineLatest, map, of, switchMap, tap } from 'rxjs';
import { NavigationItem } from '../models/navigation-item.model';

export type TownhallApplication =
  | 'global'
  | 'budget'
  | 'timetracking'
  | 'skillerd';
export type RouteNameResolver = (
  type: string,
  id: number,
) => Observable<string>;

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  public getNavigationItemsFromRouterState(
    state: RouterState,
    routeConfigs: Route[],
    app: TownhallApplication,
    nameResolver: RouteNameResolver | null,
  ): Observable<NavigationItem[]> {
    let current = state.root;
    // eslint-disable-next-line no-constant-condition
    while (true) {
      if (current.firstChild) {
        current = current.firstChild;
      } else {
        break;
      }
    }

    return this.getItemsToRoot(current, app, nameResolver).pipe(
      map((items) => {
        return this.nestItems(items, 0);
      }),
      switchMap((item) => {
        const items = [item].filter((x) => !!x);
        const subAppRoutes =
          (
            routeConfigs.find(
              (r) => r.data?.['type'] === 'app' && r.path === app,
            ) as any
          )?._loadedRoutes ?? [];

        if (subAppRoutes.length === 1 && subAppRoutes[0].path === '') {
          return this.fillNavigationItemsByLevel(
            items,
            subAppRoutes[0].children ?? [],
            app,
          ).pipe(map((items) => items.filter((itm) => itm.link !== app)));
        } else {
          // global sidebar
          const globalRoutes =
            (routeConfigs.filter(
              (r) => r.data?.['type'] === 'global',
            ) as any) ?? [];
          return this.fillNavigationItemsByLevel(items, globalRoutes, '');
        }
      }),
    );
  }

  private fillNavigationItemsByLevel(
    items: NavigationItem[],
    leveledRoutes: Route[],
    parentLink: string,
  ): Observable<NavigationItem[]> {
    return of(items).pipe(
      map((items) => {
        items = items.map((i) => {
          const leveledChilds = (
            (leveledRoutes.find((f) => f.path === i.path) as any)
              ?._loadedRoutes ?? []
          ).filter((s: Route) => !!s.path) as Route[];
          if (
            leveledChilds.length === 1 &&
            leveledChilds[0].path?.startsWith('edit/') &&
            i.childs?.length === 1 &&
            i.childs[0].path.startsWith('edit/')
          ) {
            const itemChilds = i.childs[0].childs ?? [];
            const routeChilds =
              leveledChilds[0].children?.filter((s) => !!s.path) ?? [];
            this.createItemsOfRoutes(routeChilds, itemChilds, i.childs[0].link);
          } else if (
            leveledChilds.length > 1 &&
            !leveledChilds[0].path?.startsWith('edit/') &&
            i.childs?.length === 1
          ) {
            const itemChilds = i.childs ?? [];
            this.createItemsOfRoutes(leveledChilds, itemChilds, i.link);
          }
          return i;
        });

        this.createItemsOfRoutes(leveledRoutes, items, parentLink);
        return items;
      }),
    );
  }

  private createItemsOfRoutes(
    routeChilds: Route[],
    items: NavigationItem[],
    parentLink: string,
  ): void {
    let foundItem = false;
    for (let index = 0; index < routeChilds.length; index++) {
      const routeChild = routeChilds[index];
      if (items?.map((itm) => itm.path).includes(routeChild.path ?? '')) {
        foundItem = true;
        continue;
      }

      const newItem: NavigationItem = {
        title$: of(routeChild.data?.['title']),
        icon: routeChild.data?.['icon'],
        neededRoles: routeChild.data?.['neededRoles'] ?? [],
        link: routeChild.path ? `${parentLink}/${routeChild.path}` : parentLink,
        path: routeChild.path ?? '',
        childs: [],
        selected: false,
      };

      if (foundItem) {
        items?.push(newItem);
      } else {
        items?.splice(index, 0, newItem);
      }
    }
  }

  private nestItems(
    items: NavigationItem[],
    currentIndex: number,
  ): NavigationItem {
    const current = items[currentIndex];

    if (items.length > currentIndex + 1) {
      current.childs = [this.nestItems(items, currentIndex + 1)];
    }

    return current;
  }

  public getBreadcrumbsFromRoute(
    route: ActivatedRoute,
    app: TownhallApplication,
    nameResolver: RouteNameResolver | null,
  ): Observable<NavigationItem[]> {
    return this.getItemsToRoot(route, app, nameResolver);
  }

  private getItemsToRoot(
    route: ActivatedRoute,
    app: TownhallApplication,
    nameResolver: RouteNameResolver | null,
  ): Observable<NavigationItem[]> {
    const observables = [];
    let currentRoute: ActivatedRoute | null = route;
    // eslint-disable-next-line no-constant-condition
    while (true) {
      observables.push(this.getItemOfRoute(currentRoute, nameResolver));
      currentRoute = currentRoute.parent;
      if (!currentRoute) {
        break;
      }
    }

    return combineLatest(observables).pipe(
      map((items) => items.filter((i) => i.link && i.link !== app)),
      map((items) => items.reverse()),
      tap((items) => {
        if (items[0] && !items[0]?.link?.startsWith(app) && app !== 'global') {
          items[0].link = `${app}/${items[0]?.link}`;
        }
      }),
      tap((items) =>
        items.forEach((current, i) => {
          current.link =
            i === 0
              ? current.link
              : !current.link.startsWith(items[i - 1].link)
                ? `${items[i - 1].link}/${current.link}`
                : current.link;
        }),
      ),
    );
  }

  private getItemOfRoute(
    route: ActivatedRoute,
    nameResolver: RouteNameResolver | null,
  ): Observable<NavigationItem> {
    return combineLatest([
      route.data,
      route.url,
      route.params,
      route.parent?.url ?? of([]),
    ]).pipe(
      map(
        ([data, segments, params, parentSegments]) =>
          ({
            title$:
              !data['title'] && segments[0]?.path === 'edit'
                ? nameResolver
                  ? nameResolver(
                      parentSegments[0]?.path,
                      parseInt(params['id'], 10),
                    )
                  : of(params['id'])
                : of(data['title']),
            icon: data['icon'],
            neededRoles: data['neededRoles'] ?? [],
            link:
              data['isVisible'] === false
                ? ''
                : segments.map((s) => s.path).join('/'),
            path: segments.map((s) => s.path).join('/'),
            selected: true,
          }) as NavigationItem,
      ),
    );
  }
}
