export enum UserRole {
  // Generic
  AdminRole = 'Admin',

  // Budget
  ProjectAdminRole = 'ProjectAdmin',
  ProjectControllerRole = 'ProjectController',
  CustomerRole = 'Customer',

  // Budget, Skillerd
  CompanyAdminRole = 'CompanyAdmin',
  LeadRole = 'Lead',

  // Timetracking, Skillerd
  EmployeeRole = 'Employee',
}
