export const environment = {
  apiUrl: 'https://dev.api.townhall.nerdware.dev/',
  coreApiUrl: 'https://dev.api.townhall.nerdware.dev/core',
  budgetApiUrl: 'https://dev.api.townhall.nerdware.dev/budget',
  timeTrackingApiUrl: 'https://dev.api.townhall.nerdware.dev/timetracking',
  skillerdApiUrl: 'https://dev.api.townhall.nerdware.dev/skillerd',
  townhallAuth: {
    clientId: 'townhall-client',
    issuer: 'https://api.townhall.nerdware.dev',
    scopes: 'openid profile email',
  },
  azureAuth: {
    clientId: 'b128e423-31ac-4110-b622-781befaa908c',
    issuer:
      'https://login.microsoftonline.com/272e8b0f-9db3-45c2-8b5a-682bbfea9992/v2.0',
    scopes: 'openid profile',
  },
};
