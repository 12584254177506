import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule, DecimalPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import {
  TippyDirective,
  popperVariation,
  provideTippyConfig,
  tooltipVariation,
} from '@ngneat/helipopper';
import { TranslateModule } from '@ngx-translate/core';
import { ToastrModule } from 'ngx-toastr';
import { PaginatorModule } from 'primeng/paginator';
import { TableModule } from 'primeng/table';
import { environment } from '../environment/environment';
import { AssignmentInputComponent } from './assignment-input/assignment-input.component';
import { AssignmentInputDialogComponent } from './assignment-input/dialog/assignment-input-dialog.component';
import { AuthModule } from './auth.module';
import { BadgeComponent } from './badge/badge.component';
import { BarChartComponent } from './bar-chart/bar-chart.component';
import { BreadCrumbComponent } from './bread-crumb/bread-crumb.component';
import { ButtonComponent } from './button/button.component';
import { CardLayoutBoxComponent } from './card-layout-box/card-layout-box.component';
import { CardLayoutComponent } from './card-layout/card-layout.component';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { CounterCircleComponent } from './counter-circle/counter-circle.component';
import { DialogComponent } from './dialog/dialog.component';
import { DropdownComponent } from './dropdown/dropdown.component';
import { EmployeeWidgetComponent } from './employee-widget/employee-widget.component';
import { FlipswitchComponent } from './flipswitch/flipswitch.component';
import { InputComponent } from './input/input.component';
import { PageHeadComponent } from './page-head/page-head.component';
import { PercentageWidgetComponent } from './percentage-widget/percentage-widget.component';
import { BooleanPipe } from './pipes/boolean.pipe';
import { CompanyNamePipe } from './pipes/company-name.pipe';
import { DurationPipe } from './pipes/duration.pipe';
import { EmployeeNamePipe } from './pipes/employee-name.pipe';
import { NumberFormatPipe } from './pipes/number-format.pipe';
import { PureFunctionPipe } from './pipes/pure-function.pipe';
import { ProjectBudgetReportingComponent } from './project-budget-reporting/project-budget-reporting.component';
import { TableStorageService } from './services/table-data.service';
import { SidebarComponent } from './sidebar/sidebar.component';
import { StackedBarChartComponent } from './stacked-bar-chart/stacked-bar-chart.component';
import { TabbarComponent } from './tabbar/tabbar.component';
import { TableConfigurationComponent } from './table/components/table-configuration/table-configuration.component';
import { TableFilterDialogComponent } from './table/components/table-filter-dialog/table-filter-dialog.component';
import { TableComponent } from './table/table.component';
import { TagsComponent } from './tags/tags.component';
import { CustomToastComponent } from './toast/custom-toast.component';
import { UserInfoComponent } from './user-info/user-info.component';
import { ProjectBudgetReportingTooltipComponent } from './project-budget-reporting/project-budget-reporting-tooltip/project-budget-reporting-tooltip.component';
import { TimePipe } from './pipes/time.pipe';
import { CalendarModule } from 'primeng/calendar';

@NgModule({
  declarations: [
    ButtonComponent,
    InputComponent,
    DropdownComponent,
    TableComponent,
    SidebarComponent,
    TabbarComponent,
    BooleanPipe,
    PureFunctionPipe,
    NumberFormatPipe,
    CustomToastComponent,
    ConfirmationDialogComponent,
    DialogComponent,
    UserInfoComponent,
    BarChartComponent,
    PercentageWidgetComponent,
    EmployeeWidgetComponent,
    CounterCircleComponent,
    DurationPipe,
    TagsComponent,
    AssignmentInputComponent,
    AssignmentInputDialogComponent,
    StackedBarChartComponent,
    CardLayoutComponent,
    CardLayoutBoxComponent,
    CompanyNamePipe,
    ProjectBudgetReportingComponent,
    ProjectBudgetReportingTooltipComponent,
    EmployeeNamePipe,
    BreadCrumbComponent,
    PageHeadComponent,
    FlipswitchComponent,
    BadgeComponent,
    TableFilterDialogComponent,
    TableConfigurationComponent,
  ],
  imports: [
    CommonModule,
    CalendarModule,
    FormsModule,
    ReactiveFormsModule,
    TableModule,
    PaginatorModule,
    NgSelectModule,
    TippyDirective,
    AuthModule,
    OverlayModule,
    AuthModule.forRoot(environment.azureAuth, environment.townhallAuth),
    TranslateModule.forChild(),
    ToastrModule.forRoot({
      toastComponent: CustomToastComponent,
      preventDuplicates: false,
    }),
    TimePipe,
  ],
  exports: [
    TableModule,
    PaginatorModule,
    ButtonComponent,
    InputComponent,
    DropdownComponent,
    PercentageWidgetComponent,
    BarChartComponent,
    EmployeeWidgetComponent,
    CounterCircleComponent,
    TableComponent,
    SidebarComponent,
    TabbarComponent,
    BooleanPipe,
    PureFunctionPipe,
    NumberFormatPipe,
    ConfirmationDialogComponent,
    DialogComponent,
    UserInfoComponent,
    DurationPipe,
    TagsComponent,
    AssignmentInputComponent,
    StackedBarChartComponent,
    CardLayoutComponent,
    CardLayoutBoxComponent,
    CompanyNamePipe,
    ProjectBudgetReportingComponent,
    EmployeeNamePipe,
    BreadCrumbComponent,
    PageHeadComponent,
    FlipswitchComponent,
    BadgeComponent,
  ],
  providers: [
    TableStorageService,
    ConfirmationDialogComponent,
    DecimalPipe,
    provideTippyConfig({
      defaultVariation: 'tooltip',
      variations: {
        tooltip: tooltipVariation,
        popper: popperVariation,
      },
    }),
  ],
})
export class SharedModule {}
