import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BehaviorSubject, debounceTime, skip, Subscription, tap } from 'rxjs';
import { HelperService } from '../services/helper.service';

@Component({
  selector: 'th-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputComponent),
      multi: true,
    },
  ],
})
export class InputComponent
  implements AfterViewInit, OnDestroy, OnInit, ControlValueAccessor
{
  private _value = new BehaviorSubject<any>(this.value);
  private valueSubscription!: Subscription;
  public isInitialized = false;

  asDate = (value: string) => {
    return new Date(value);
  };

  @ViewChild('input') private inputComponent!: ElementRef<HTMLInputElement>;
  @Input()
  get value(): any {
    return this._value?.value;
  }
  set value(value: any) {
    if (this._value.value === value) {
      return;
    }
    this._value.next(value);
    this.onChange(value);
  }

  @Input() isInline = false;
  @Input() placeholder = '';
  @Input() labelText = '';
  @Input() matIcon?: string;
  @Input() maxDate?: string;
  @Input() minTime?: string;
  @Input() maxTime?: string;
  @Input() type:
    | 'text'
    | 'number'
    | 'email'
    | 'date'
    | 'password'
    | 'time'
    | 'checkbox'
    | 'textarea' = 'text';
  @Input() isDisabled = false;
  @Input() tooltipText? = '';
  @Input() onValidate!: (input: any) => boolean;
  @Input() noMargin = false;
  @Input() debounce = 0;
  @Input() min!: number;
  @Input() max!: number;
  @Input() emitInitialValueChange = true;
  @Input() transparent = false;
  @Input() darkBg = false;
  @Output() valueChange = new EventEmitter<any>();
  @Output() enterPressed = new EventEmitter<void>();

  constructor(public helperService: HelperService) {}

  ngAfterViewInit(): void {
    this.validate(null);
  }

  ngOnInit(): void {
    if (!this.isInitialized && this.emitInitialValueChange) {
      this.valueChange.emit(this.value);
    }

    if (this.type !== 'date') {
      this.isInitialized = true;
      this._value.next(this.value);
    } else {
      setTimeout(() => {
        if (this.value && new Date(this.value).toString() !== 'Invalid Date') {
          this._value.next(new Date(this.value));
          this.isInitialized = true;
        } else {
          this.isInitialized = true;
        }
      }, 0);
    }

    this.valueSubscription = this._value
      .pipe(
        debounceTime(this.debounce),
        skip(1),
        tap((v) => this.valueChange.emit(v)),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.valueSubscription?.unsubscribe();
  }

  public validate(evt: Event | null): void {
    if (this.onValidate) {
      const isValid = this.onValidate(this.inputComponent.nativeElement.value);
      this.inputComponent.nativeElement.setAttribute(
        'aria-invalid',
        `${!isValid}`,
      );
    }

    if (evt instanceof KeyboardEvent && evt?.key === 'Enter') {
      this.enterPressed.emit();
    }
  }

  onTimeChange(time: string) {
    const [hours, minutes] = time.split(':').map(Number);
    const currentDate = this.value ? new Date(this.value) : new Date();
    currentDate.setHours(hours, minutes);
    this.value = currentDate;
  }

  //#region Control Value Accessor
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange: any = () => {};
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouch: any = () => {};
  writeValue(value: any) {
    if (this._value.value === value) {
      return;
    }
    if (
      value &&
      typeof value === 'string' &&
      ['time', 'date'].includes(this.type) &&
      !isNaN(Date.parse(value))
    ) {
      value = new Date(value);
    }

    this._value.next(value);
  }
  registerOnChange(fn: any) {
    this.onChange = fn;
  }
  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }
  //#endregion
}
