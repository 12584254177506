import { Inject, Injectable, InjectionToken, Injector } from '@angular/core';
import { Router } from '@angular/router';
import {
  AuthConfig,
  OAuthEvent,
  OAuthService,
  ParsedIdToken,
  TokenResponse,
} from 'angular-oauth2-oidc';
import { BehaviorSubject, Observable, lastValueFrom } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthClientConfig } from '../models/auth-client-config.model';
import { UserInfo } from '../models/user-info.model';
import { EmulationService } from './emulation.service';
import { StorageService } from './storage.service';
import { UserService } from './user.service';

const StorageKey = 'townhall-redirect-key';
export const AuthAzureConfigService = new InjectionToken<AuthClientConfig>(
  'AuthAzureConfigService',
);
export const AuthTownhallConfigService = new InjectionToken<AuthClientConfig>(
  'AuthTownhallConfigService',
);

@Injectable()
export class AuthService {
  public isAuthenticated$ = new BehaviorSubject<boolean>(false);

  private get router(): Router {
    return this.injector.get(Router);
  }

  public getIdToken(): string {
    return this.oauthService.getIdToken();
  }

  public getEmulationToken(): string {
    return localStorage.getItem('emulation_token') ?? '';
  }

  constructor(
    private injector: Injector,
    private storageService: StorageService,
    private oauthService: OAuthService,
    private userService: UserService,
    private emulationService: EmulationService,
    @Inject(AuthAzureConfigService) private azureConfig: AuthClientConfig,
    @Inject(AuthTownhallConfigService) private townhallConfig: AuthClientConfig,
  ) {
    this.listenOnOAuthEvents();
  }

  public openLoginUri(uri: string): void {
    window.location.href = uri;
  }

  /**
   * @param redirectPath where to redirect to after an successful login
   */
  public async loginAzure(redirectPath?: string): Promise<void> {
    await this.initOAuthService('azure');

    try {
      await this.oauthService.initLoginFlow();
      await this.storageService.set(StorageKey, redirectPath ?? '/');
    } catch (e) {
      console.error(e);
    }
  }

  public async login(
    email: string,
    password: string,
  ): Promise<{ info: UserInfo; token: string }> {
    await this.initOAuthService('townhall');
    const response = await lastValueFrom(
      this.userService.createBasicToken(email, password),
    );
    const token = await this.oauthService.processIdToken(
      response?.token as string,
      response?.token as string,
      true,
    );
    this.storeIdToken(token);
    this.isAuthenticated$.next(true);
    return response as { info: UserInfo; token: string };
  }

  public logOff(): void {
    const isOwnToken = this.isOwnToken();
    if (isOwnToken) {
      this.oauthService.logOut(true);
    } else {
      this.oauthService.logOut();
    }

    // redirect to root of page
    this.router.navigate(['/login']);
    this.clearEmulationToken();
  }

  private listenOnOAuthEvents(): void {
    this.isAuthenticated$.next(this.oauthService.hasValidIdToken());
    this.oauthService.events
      .pipe(
        tap(() =>
          this.isAuthenticated$.next(this.oauthService.hasValidIdToken()),
        ),
      )
      .subscribe((event: OAuthEvent) => {
        if (event.type === 'token_expires') {
          this.oauthService
            .refreshToken()
            .then(() => {
              console.log('Token has been successfully refreshed.');
            })
            .catch((error) => {
              console.error('Error during token refresh:', error);
            });
        }
      });
  }

  public async handleLoginCallback(): Promise<UserInfo> {
    await this.initOAuthService('azure');
    await this.oauthService.tryLoginCodeFlow();
    const stateUrl = this.storageService.get<string>(StorageKey) || '/';
    this.storageService.remove(StorageKey);
    await this.router.navigateByUrl(stateUrl);
    return lastValueFrom(this.userService.getUserInfos()) as Promise<UserInfo>;
  }

  public getUserInfos(): Observable<UserInfo> {
    return this.userService.getUserInfos();
  }

  public async initOAuthService(
    configHint?: 'azure' | 'townhall',
  ): Promise<void> {
    const useTownhallConfig = configHint === 'townhall' || this.isOwnToken();
    const config = useTownhallConfig ? this.townhallConfig : this.azureConfig;
    const authConfig = {
      clientId: config.clientId,
      issuer: config.issuer,
      redirectUri: `${new URL(location.href).origin}/auth/callback`,
      responseType: 'code',
      scope: config.scopes,
      showDebugInformation: true,
      strictDiscoveryDocumentValidation: false,
      skipIssuerCheck: true,
      openUri: this.openLoginUri.bind(this),
      timeoutFactor: 0.5,
    } as AuthConfig;

    this.oauthService.configure(authConfig);
    if (!useTownhallConfig) {
      await this.oauthService.loadDiscoveryDocument();
    }
  }

  public async renewStaleSession(): Promise<void> {
    if (!this.isAuthenticated$.value && !!this.oauthService.getRefreshToken()) {
      try {
        await this.refreshToken();
      } catch {
        console.info('Could not renew session with refresh-token');
      }
    }
  }

  public getRemainingTime(): number {
    const expirationInMilliseconds =
      this.oauthService.getIdTokenExpiration() - Date.now();
    return expirationInMilliseconds / 1000;
  }

  public async refreshToken(): Promise<TokenResponse | null> {
    if (this.oauthService.clientId === 'townhall-client') {
      const response = await lastValueFrom(
        this.userService.refreshBasicToken(this.getIdToken()),
      );
      const token = await this.oauthService.processIdToken(
        response?.token as string,
        response?.token as string,
        true,
      );
      this.storeIdToken(token);
      return null;
    } else {
      await this.initOAuthService();
      return await this.oauthService.refreshToken();
    }
  }

  public emulate(
    userId: number,
    targetUserId: number,
  ): Observable<{ token: string }> {
    return this.emulationService
      .createEmulationToken(userId, targetUserId)
      .pipe(
        tap(({ token }) => this.storeEmulationToken(token)),
        tap(() => this.router.navigate(['/']).then(() => location.reload())),
      );
  }

  public stopEmulation(): void {
    this.clearEmulationToken();
    this.router.navigate(['/']).then(() => location.reload());
  }

  private isOwnToken(): boolean {
    let decoded = '{}';
    const token = this.getIdToken();

    if (token) {
      decoded = this.b64DecodeUnicode(
        token.split('.')[0].replace(/-/g, '+').replace(/_/g, '/'),
      );
    }

    const header = JSON.parse(decoded);
    return !!token && !header?.kid;
  }

  private b64DecodeUnicode(str: string): string {
    // Going backwards: from bytestream, to percent-encoding, to original string.
    return decodeURIComponent(
      atob(str)
        .split('')
        .map((char) => '%' + ('00' + char.charCodeAt(0).toString(16)).slice(-2))
        .join(''),
    );
  }

  private storeIdToken(idToken: ParsedIdToken): void {
    localStorage.setItem('id_token', idToken.idToken);
    localStorage.setItem('id_token_claims_obj', idToken.idTokenClaimsJson);
    localStorage.setItem('id_token_expires_at', '' + idToken.idTokenExpiresAt);
    localStorage.setItem('id_token_stored_at', '' + Date.now());
  }

  private storeEmulationToken(token: string): void {
    localStorage.setItem('emulation_token', token);
  }

  public clearEmulationToken(): void {
    localStorage.removeItem('emulation_token');
  }
}
