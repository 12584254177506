import {
  AfterRenderPhase,
  ApplicationRef,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  Type,
  ViewChild,
  afterRender,
  createComponent,
} from '@angular/core';
import { Observable, skip } from 'rxjs';
import { Point } from '../models/point.model';
import { IDialog } from './dialog.interface';

export interface DialogData {
  title: string;
  type?: 'info' | 'question' | 'warning' | 'error';
  hasCancelButton?: boolean;
  cancelButtonText?: string;
  confirmButtonText?: string;
  hasOutsideDialogClickListener?: boolean;
  isFullScreenActive?: boolean;
}

@Component({
  selector: 'th-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
})
export class DialogComponent {
  @Input() dialogData!: DialogData & { referencePosition?: DOMRect };
  @Input() hasSpacer = false;
  @Input() customWidth!: string;
  @Input() isConfirmAllowed = true;
  @Input() hasConfirmButton = true;
  @Input() hasConfirmAndNewButton = false;
  @Input() isTitleWarning = false;
  @Output() confirm = new EventEmitter<void>();
  @Output() confirmAndNew = new EventEmitter<void>();
  @Output() cancel = new EventEmitter<void>();
  @ViewChild('dialogRef') dialogRef!: ElementRef<HTMLDialogElement>;

  public calculatedDialogPosition!: Point | undefined;

  get icon(): string {
    switch (this.dialogData.type) {
      case 'info':
        return 'info';
      case 'question':
        return 'help_outline';
      case 'warning':
        return 'warning';
      case 'error':
        return 'error';
      default:
        return '';
    }
  }

  constructor(cdr: ChangeDetectorRef) {
    afterRender(() => this.repositionRelativeDialog(cdr), {
      phase: AfterRenderPhase.Read,
    });
  }

  private repositionRelativeDialog(cdr: ChangeDetectorRef): void {
    if (this.dialogData.referencePosition) {
      const content =
        this.dialogRef.nativeElement.querySelector('.dialog-content');
      const rect = content?.getBoundingClientRect();

      if (rect) {
        const windowRect = document.body.getBoundingClientRect();
        let calculatedPosition: Point | undefined = {
          x: this.dialogData.referencePosition.x,
          y: this.dialogData.referencePosition.y,
        } as Point;
        const margin = 20;

        if (calculatedPosition.x + rect.width + margin > windowRect.width) {
          calculatedPosition.x = windowRect.width - rect.width - margin;
        }

        if (calculatedPosition.y + rect.height + margin > windowRect.height) {
          calculatedPosition.y = windowRect.height - rect.height - margin;
        }

        if (calculatedPosition.x < 0 || calculatedPosition.y < 0) {
          calculatedPosition = undefined;
        }

        if (
          (this.calculatedDialogPosition?.x !== calculatedPosition?.x ||
            this.calculatedDialogPosition?.y !== calculatedPosition?.y) &&
          calculatedPosition
        ) {
          this.calculatedDialogPosition = calculatedPosition;
          cdr.detectChanges();
        }
      }
    }
  }

  @HostListener('document:click', ['$event'])
  onClickOutsideDialog(event: MouseEvent): void {
    const targetElement = event.target as HTMLElement;

    if (typeof targetElement.className !== 'string') {
      return;
    }

    if (
      targetElement.className.includes('dialog-content-container') &&
      this.dialogData.hasOutsideDialogClickListener
    ) {
      this.dialogRef.nativeElement.close();
    }
  }

  public static show<T extends IDialog<any, any>>(
    type: Type<T>,
    applicationRef: ApplicationRef,
    data: T['dialogData'],
    referenceElement?: HTMLElement,
  ): Observable<T['subject']['value']> {
    const instanceRef = createComponent(type, {
      environmentInjector: applicationRef.injector,
      hostElement: document.getElementById('dialog-host') as HTMLElement,
    });

    if (data.hasCancelButton === undefined) {
      data.hasCancelButton = true;
    }

    instanceRef.instance.dialogData = {
      ...data,
      referencePosition: referenceElement?.getBoundingClientRect(),
    };
    applicationRef.attachView(instanceRef.hostView);
    instanceRef.changeDetectorRef.detectChanges();
    instanceRef.instance.dialogData = {
      ...data,
      referencePosition: referenceElement?.getBoundingClientRect(),
    };
    instanceRef.instance.dialog.dialogRef.nativeElement.show();
    return instanceRef.instance.subject.pipe(skip(1));
  }
}
