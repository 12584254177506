import { Injectable } from '@angular/core';
import { Portal } from '../enums/portal.enum';
import { UserRole } from '../enums/user-role.enum';
import { UserState } from '../state/user.state';

@Injectable({
  providedIn: 'root',
})
export class RoleService {
  constructor(private userState: UserState) {}

  public isAuthorized(
    neededRoles: UserRole[],
    projectId?: number,
    portal?: string,
  ): boolean {
    const currentUser = this.userState.userInfo;
    if (!currentUser) {
      return false;
    }

    if (!neededRoles.length) {
      return true;
    }

    const portalRole = portal
      ? currentUser.emulatedRoles?.[portal] ?? currentUser.roles[portal]
      : null;
    const rolesOfUser = portal
      ? [portalRole]
      : Object.values(
          Object.keys(currentUser.emulatedRoles ?? {}).length > 0
            ? currentUser.emulatedRoles ?? {}
            : currentUser.roles,
        );

    return (
      neededRoles.some((needed) => rolesOfUser.includes(needed)) &&
      (neededRoles.includes(UserRole.CompanyAdminRole) ||
        !projectId ||
        currentUser.projectIds?.includes(projectId))
    );
  }

  public isController(projectId?: number): boolean {
    return this.isAuthorized(
      [
        UserRole.ProjectControllerRole,
        UserRole.ProjectAdminRole,
        UserRole.CompanyAdminRole,
      ],
      projectId,
    );
  }

  public isProjectAdmin(projectId?: number): boolean {
    return this.isAuthorized(
      [UserRole.CompanyAdminRole, UserRole.ProjectAdminRole],
      projectId,
    );
  }

  public isCompanyAdmin(projectId?: number): boolean {
    return this.isAuthorized([UserRole.CompanyAdminRole], projectId);
  }

  public isCustomer(projectId?: number): boolean {
    return this.isAuthorized([UserRole.CustomerRole], projectId);
  }

  public isLead(projectId?: number): boolean {
    return this.isAuthorized([UserRole.LeadRole], projectId);
  }

  public isAdmin(): boolean {
    return this.isAuthorized([UserRole.AdminRole]);
  }

  public canAccessApp(portal: Portal): boolean {
    const currentUser = this.userState.userInfo;
    if (!currentUser) return false;

    return portal in currentUser.roles;
  }
}
