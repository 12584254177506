<div
  class="relative"
  [class.checkbox]="type === 'checkbox'"
  [class.items-center]="type !== 'checkbox' && isInline"
  [class.items-start]="type === 'checkbox' && isInline"
  [ngClass]="isInline ? 'flex-row-reverse flex gap-2.5' : ''"
>
  <label
    *ngIf="labelText"
    class="text-base input-label"
    [class.checkbox]="type === 'checkbox'"
    >{{ labelText }}
    <div
      *ngIf="helperService.isHelpModeEnabled$ | async"
      class="tooltip"
      tp="{{ tooltipText }}"
      data-tippy-open
      aria-describedby="tippy-6"
    >
      <span class="icon material-symbols-outlined input-info">info</span>
    </div>
  </label>

  <input
    *ngIf="!['date', 'checkbox', 'number', 'textarea', 'time'].includes(type)"
    (blur)="onTouch(value)"
    [type]="type"
    [(ngModel)]="value"
    [class.mt-2]="!noMargin"
    [class.disabled]="isDisabled"
    [class.icon-spacing]="matIcon"
    [class.transparent]="transparent"
    [class.dark-bg]="darkBg"
    [placeholder]="placeholder"
    [disabled]="isDisabled"
    (keyup)="validate($event)"
    #input
  />
  <p-calendar
    *ngIf="type === 'date' && isInitialized"
    [(ngModel)]="value"
    [monthNavigator]="true"
    [yearNavigator]="true"
    [showWeek]="false"
    dateFormat="dd.mm.yy"
    [firstDayOfWeek]="1"
    (ngModelChange)="value = asDate($event)"
    [showButtonBar]="true"
    [showIcon]="true"
    [disabled]="isDisabled"
  >
    <ng-template
      *ngIf="type === 'date'"
      class="calendar-container"
      pTemplate="triggericon"
    >
      <span
        class="calendar-icon material-symbols-outlined flex justify-between cursor-pointer"
        >event</span
      >
    </ng-template>
  </p-calendar>
  <input
    *ngIf="type === 'time'"
    (blur)="onTouch(value)"
    [type]="type"
    [ngModel]="value | time"
    (ngModelChange)="onTimeChange($event)"
    [class.mt-2]="!noMargin"
    [placeholder]="placeholder"
    [disabled]="isDisabled"
    (keyup)="validate($event)"
    [min]="minTime"
    [max]="maxTime"
    #input
  />
  <input
    *ngIf="type === 'number'"
    (blur)="onTouch(value)"
    [type]="type"
    [valueAsNumber]="value"
    (change)="value = $any($event.target).valueAsNumber; validate($event)"
    [class.mt-2]="!noMargin"
    [placeholder]="placeholder"
    [disabled]="isDisabled"
    [min]="min"
    [max]="max"
    #input
  />
  <input
    *ngIf="type === 'checkbox'"
    (blur)="onTouch(value)"
    [type]="type"
    [checked]="value"
    (change)="value = $any($event.target).checked"
    [class.mt-2]="!noMargin"
    [placeholder]="placeholder"
    [disabled]="isDisabled"
    (keyup)="validate($event)"
    #input
  />
  <textarea
    *ngIf="type === 'textarea'"
    (blur)="onTouch(value)"
    [(ngModel)]="value"
    [class.mt-2]="!noMargin"
    [placeholder]="placeholder"
    [disabled]="isDisabled"
    (keyup)="validate($event)"
    #input
  ></textarea>
  <span
    *ngIf="matIcon"
    (blur)="onTouch(value)"
    [class.mt-2]="!noMargin"
    class="material-symbols-outlined absolute inset-y-2 right-0 flex items-center pr-3 pointer-events-none"
    >{{ matIcon }}
  </span>
</div>
