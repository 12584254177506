import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, catchError, tap, throwError } from 'rxjs';
import { UserState } from '../state/user.state';

@Injectable()
export class ResponseInterceptor implements HttpInterceptor {
  private ignoredRequests = ['/calculate', '/token'];

  constructor(
    private toastrService: ToastrService,
    private userState: UserState,
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap((response: HttpEvent<any>) => {
        if (
          response.type === 4 &&
          (response as HttpResponse<any>)?.ok &&
          ['POST', 'PATCH', 'PUT', 'DELETE'].includes(request.method) &&
          !this.ignoredRequests.some((r) => request.url.includes(r))
        ) {
          this.toastrService.success(
            'Your changes have been saved!',
            'Success',
          );
        }
      }),
      catchError((error: HttpErrorResponse) => {
        if (
          (request.url.endsWith('/user/info') ||
            request.url.endsWith('/token')) &&
          !this.userState.isAuthenticated
        ) {
          return throwError(() => error);
        } else if (error.status === 403) {
          this.toastrService.error(
            `You are not authorized to see this data!`,
            'Error',
          );
          return throwError(() => error);
        }

        this.toastrService.error(error.error.error, 'Error');
        return throwError(() => error);
      }),
    );
  }
}
